<template>
	<div class="header-search">
		<el-popover placement="bottom" class="search-span" trigger="manual" v-model="visible">
			<el-input
				class="search-input"
				:placeholder="searchPlaceHolder"
				:style="{ width: '100%' }"
				:class="'white'"
				v-model="searchVal"
				slot="reference"
				@keyup.enter.native="search"
				@focus="visible = true"
				@blur="visible = false"
				style=" caret-color:white;"
			>
				<img
					:src="seachImage"
					alt=""
					slot="prefix"
					style="width: 20px; height: 20px; margin-top: 25px; margin-left: 12px"
				/>
			</el-input>
			<div class="search-history" :style="{ width: historyWidth + 'px' }">
				<div class="history-item-box">
					<div
						@click="searchItem(item)"
						class="history-item"
						v-for="(item, index) in historyList"
						:key="index"
					>
						<span>{{ item }}</span>
						<img @click.stop="removeHistory(index)" :src="closeIcon" alt="" />
					</div>
				</div>
				<div class="search-history-reset" @click="resetSearch()">
					清除搜索历史
				</div>
			</div>
		</el-popover>
		<div class="search-right">
			<div
				class="right-subscribe"
				@click="goCenter(4)"
				v-show="isLogin && userData.memberPlan && userData.memberPlan.name"
			>
				{{ userData.memberPlan.name }}
			</div>
			<div class="right-avatar">
				<img
					:src="isLogin ? getImg(userData.userInfo.avatar, 70, 70) : avatarImage"
					@click="dialogVisible = true"
					v-show="!isLogin"
				/>
				<el-popover
					placement="top-start"
					width="180"
					trigger="hover"
					v-show="isLogin"
					style="width: 70px; height: 70px; display: block"
				>
					<img :src="isLogin ? getImg(userData.userInfo.avatar, 70, 70) : avatarImage" slot="reference" />
					<div class="hover-box">
						<div class="hover-item" @click="go('/center/main')">
							<img :src="centerImage" alt="" />
							<span>个人中心</span>
						</div>
						<div class="hover-item" @click="logOut()">
							<img :src="outImage" alt="" />
							<span>退出</span>
						</div>
					</div>
				</el-popover>
			</div>
		</div>

		<el-dialog
			:title="titleValue[type]"
			:visible="dialogVisible"
			class="login-style"
			width="500px"
			:append-to-body="true"
			:close-on-click-modal="true"
			:show-close="false"
		>
				<span slot="title" >
					<span>{{titleValue[type]}}</span>
					<i class="el-icon-close" style="float:right;cursor: pointer" @click="dialogVisible = false;"></i>
				</span>
			<div v-loading="loginLoading">
				<el-form ref="formLogin" :model="formLogin" label-width="80px">
					<el-form-item label="手机号">
						<el-input placeholder="请输入手机号" v-model="formLogin.phoneNumber"></el-input>
					</el-form-item>
					<el-form-item label="验证码" v-show="type != 'login'">
						<el-input placeholder="请输入验证码" v-model="formLogin.code">
							<el-button
								type="text"
								slot="suffix"
								style="color: #ff3465; margin-right: 12px"
								@click="getCode()"
								>{{ codeText }}</el-button
							>
						</el-input>
					</el-form-item>
					<el-form-item label="新密码" v-show="type == 'register'">
						<el-input type="password" placeholder="请输入新密码" v-model="formLogin.password_new">
						</el-input>
					</el-form-item>
					<el-form-item label="密码" v-show="type == 'login'" style="margin-bottom: 0">
						<el-input type="password" placeholder="请输入密码" v-model="formLogin.password"> </el-input>
					</el-form-item>
				</el-form>
				<div class="form-footer" v-show="type == 'login'">
					<el-button type="text" style="color: #ff3465; margin-right: 12px" @click="switchType('register')"
						>忘记密码？</el-button
					>
				</div>
				<div class="dialog-button" @click="login()">
					{{ buttonValue[type] }}
				</div>
				<div class="dialog-text" v-show="type == 'phone'">
					<div class="dialog-text-1">未注册用户将会直接使用该手机号注册</div>
					<div class="dialog-text-2">
						登录/注册即代表您同意我们的
						<span>用户协议</span>
						和
						<span>隐私政策</span>
					</div>
				</div>

				<div class="dialog-login-button" v-show="type == 'phone'" @click="switchType('login')">
					使用密码登录
				</div>
				<div
					class="dialog-login-button"
					v-show="type == 'login' || type == 'register'"
					@click="switchType('phone')"
				>
					使用手机验证码登录
				</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>

export default {
	data() {
		return {
			closeIcon: require("@assets/search/close.png"),
			visible: false,
			avatarImage: require("@assets/avatar2.png"),
			outImage: require("@assets/search/out.png"),
			seachImage: require("@assets/search/search.png"),
			centerImage: require("@assets/search/center.png"),
			searchPlaceHolder: "搜索你想要探索的视频",
			searchVal: "",
			searchWidth: 0,

			dialogTitle: "开始登录/注册",
			dialogVisible: false,
			formLogin: {
				phoneNumber: "",
				code: "",
				password: "",
				password_new: "",
			},
			// phone login register

			buttonValue: {
				phone: "登录/注册",
				login: "登录",
				register: "修改密码并返回登录",
			},
			titleValue: {
				phone: "开始登录/注册",
				login: "登录",
				register: "找回密码",
			},

			codeText: "输入验证码",
			timer: null,
			timerNumber: 60,
			loginLoading: false,
			historyList: [],
			type: "phone",

			historyWidth: 0,
			// isLogin: false,
			// userData: {
			// 	token: "",
			// 	userInfo: {
			// 		avatar: "",
			// 		createdAt: "",
			// 		gender: "",
			// 		mobile: "",
			// 		nickname: "",
			// 		signature: "",
			// 	},
			// 	memberPlan: {
			// 		expiresAt: "",
			// 		name: "",
			// 	},
			// },
		}
	},
	mounted() {
		this.searchWidth = (document.body.offsetWidth - 320) * 0.68
		this.getHistory()
		console.log(this.userData)
		this.historyWidth = (document.body.offsetWidth - 320 - 100 - 44) * 0.7

		if(this.$store.state.userData && this.$store.state.userData.token ? true : false) {
			this.api.getUserInfo().then(res => {
				this.$store.commit("setMemberPlan", res.data.memberPlan)
			}).catch(e => {
				console.log('getUserInfo()', e)
			})
		}
	},
	computed: {
		isLogin() {
			console.log(this.$store.state.userData)
			return this.$store.state.userData && this.$store.state.userData.token ? true : false
		},
		userData() {
			return this.$store.state.userData
		},
	},
	watch: {
		// "store.state.userData.token": function(newVal, oldVal) {
		// 	this.isLogin = newVal
		// 	cosnole.log(this.login)
		// },
		// "store.state.userData": function(newVal, oldVal) {
		//   this.userData = newVal
		//   console.log(this.userData)
		// },
		_searchVal: function(newVal) {
			console.log(newVal, "watch")
			this.searchVal = newVal
		},
	},
	methods: {
		reset(type = "phone") {
			this.type = type
			this.formLogin = {
				phoneNumber: "",
				code: "",
				password: "",
				password_new: "",
			}
		},
		switchType(type) {
			this.type = type
		},
		go(route) {
			this.$router.push(route)
		},
		async getCode() {
			console.log(this.formLogin.phoneNumber)
			if (!this.formLogin.phoneNumber) {
				this.$message.warning("请输入完整手机号获取验证码。")
				return
			}
			if (!/^1[3456789]\d{9}$/.test(this.formLogin.phoneNumber)) {
				this.$message.warning("手机号有误，请重填")
				return
			}
			if (this.timerNumber != 60) return
			let result
			if (this.type == "register") {
				result = await this.api.sendCodeForForget({
					mobile: this.formLogin.phoneNumber,
				})
			} else {
				result = await this.api.sendCode({
					mobile: this.formLogin.phoneNumber,
				})
			}
			console.log(result)
			if (result.code === 1) {
				this.$message.success(result.msg)

				this.timer = setInterval(() => {
					if (this.timerNumber < 0) {
						this.codeText = "输入验证码"
						this.timerNumber = 3
						clearInterval(this.timer)
					} else {
						this.codeText = String(this.timerNumber) + "秒后获取"
						this.timerNumber = this.timerNumber - 1
					}
				}, 1000)
			} else {
				this.$message.error(result.msg)
			}
		},
		async login() {
			let type = this.type
			if (type == "phone") {
				if (!this.formLogin.phoneNumber) {
					this.$message.warning("请输入完整手机号后登录。")
					return
				}
				if (!this.formLogin.code) {
					this.$message.warning("请输入验证码后登录。")
					return
				}
				this.loginLoading = true
				const result = await this.api.loginByCode({
					mobile: this.formLogin.phoneNumber,
					code: this.formLogin.code,
					deviceInfo: {
						deviceName: "ok",
						deviceUid: "2323",
						platform: "3223",
						location: "dasdd",
					},
				})
				if (result.code === 1) {
					this.$message.success("登录成功")
					this.dialogVisible = false
					this.$store.commit("setUserData", result.data)
					this.reset()
				} else {
					this.$message.error(result.msg)
				}
				console.log(result)
			} else if (type == "login") {
				if (!this.formLogin.phoneNumber) {
					this.$message.warning("请输入完整手机号后登录。")
					return
				}
				if (!this.formLogin.password) {
					this.$message.warning("请输入密码后登录。")
					return
				}
				this.loginLoading = true
				const result = await this.api.loginByPassword({
					mobile: this.formLogin.phoneNumber,
					password: this.formLogin.password,
				})
				if (result.code === 1) {
					this.$message.success("登录成功")
					this.dialogVisible = false
					this.$store.commit("setUserData", result.data)
					this.reset()
				} else {
					this.$message.error(result.msg)
				}
			} else if (type == "register") {
				if (!this.formLogin.phoneNumber) {
					this.$message.warning("请输入完整手机号后修改密码。")
					return
				}
				if (!this.formLogin.code) {
					this.$message.warning("请输入验证码后修改密码。")
					return
				}
				if (!this.formLogin.password_new) {
					this.$message.warning("请输入新密码后修改密码。")
					return
				}
				this.loginLoading = true
				const result = await this.api.editPassword({
					mobile: this.formLogin.phoneNumber,
					newPassword: this.formLogin.password_new,
					code: this.formLogin.code,
				})
				if (result.code == 1) {
					this.$message.success("修改成功")
					this.reset("login")
				} else {
					this.$message.error(result.msg)
				}
			}
			this.loginLoading = false
		},
		logOut() {
			let userData = {
				token: "",
				userInfo: {
					avatar: "",
					createdAt: "",
					gender: "",
					mobile: "",
					nickname: "",
					signature: "",
				},
				memberPlan: {
					expiresAt: "",
					name: "",
				},
			}
			this.reset()
			localStorage.removeItem("userData")
			this.$store.commit("setUserData", userData)
			this.$message.success("登出成功！")
			this.$router.push("/")
		},

		resetSearch() {
			this.historyList = []
			localStorage.removeItem("BIYOUHISTORY")
		},
		getHistory() {
			let list = localStorage.getItem("BIYOUHISTORY")
			if (list) {
				this.historyList = JSON.parse(list)
			}
		},
		search() {
			console.log(this.searchVal)
			if (this.searchVal) {
				this.historyList.unshift(this.searchVal)
				localStorage.setItem("BIYOUHISTORY", JSON.stringify(this.historyList))
			}

			this.visible = false
			document.querySelector(".search-input input").blur()
			this.searchItem(this.searchVal ? this.searchVal : "复仇者联盟")
		},
		removeHistory(index) {
			this.historyList.splice(index, 1)
			localStorage.setItem("BIYOUHISTORY", JSON.stringify(this.historyList))
		},
	},
	props: {
		_searchVal: {
			type: String,
			default: "",
		},
	},
}
</script>

<style lang="scss">
.center-main {
	position: relative;
	.avatar-setting {
		position: absolute;
		top: 275px;
		/* right: 0; */
		left: 530px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		.avatar-setting-img {
			width: 100px;
			height: 100px;
			border-radius: 50%;
			overflow: hidden;
			img {
				width: 100%;
				height: 100%;
			}
		}
		.avatar-setting-button {
			width: 80px;
			height: 35px;
			background: #ff3465;
			opacity: 1;
			border-radius: 5px;
			display: flex;
			justify-content: center;
			align-items: center;

			font-size: 14px;
			font-family: PingFang SC;
			font-weight: 500;
			line-height: 20px;
			color: #ffffff;
			margin-top: 20px;
			user-select: none;
			cursor: pointer;
		}
	}
}
.header-search {
	padding-top: 30px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	.el-input--prefix .el-input__inner {
		padding-left: 50px;
	}
	.search-span {
		width: 70%;
	}
	.search-input {
		input {
			border: 1px solid rgba(255, 255, 255, 0.1);
			background: rgba(255, 255, 255, 0.1);
			height: 70px;
      color: white;
		}
    //input::-webkit-input-placeholder { /* WebKit browsers */
    //  color: white;
    //}
    //input:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
    //  color: white;
    //}
    //input::-moz-placeholder { /* Mozilla Firefox 19+ */
    //  color: white;
    //}
    //input:-ms-input-placeholder { /* Internet Explorer 10+ */
    //  color: white;
    //}
	}

	.search-right {
		display: flex;
		justify-content: flex-end;
		align-items: center;
		margin-left: 12px;
		width: 30%;
		.right-subscribe {
			width: 120px;
			height: 50px;
			border: 2px solid rgba(255, 255, 255, 0.5019607843137255);
			opacity: 1;
			border-radius: 5px;
			font-size: 14px;
			font-family: PingFang SC;
			font-weight: 500;
			line-height: 20px;
			color: #ffffff;
			display: flex;
			justify-content: center;
			align-items: center;
		}
		.right-subscribe:hover {
			cursor: pointer;
			border-color: #ff3465;
			color: #ff3465;
		}
		.right-avatar {
			width: 70px;
			height: 70px;
			border-radius: 50%;
			overflow: hidden;
			margin-left: 40px;
			flex-grow: 0;
			flex-shrink: 0;
			cursor: pointer;
			img {
				width: 100%;
				height: 100%;
			}
		}
	}
}
.hover-box {
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-items: flex-start;
	.hover-item {
		height: 60px;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		padding-left: 20px;
		img {
			width: 30px;
			height: 30px;
		}
		span {
			font-size: 15px;
			font-family: PingFang SC;
			font-weight: 400;
			line-height: 21px;
			color: #797a8e;
			user-select: none;
			cursor: pointer;
		}
	}
	.hover-item:hover {
		span {
			color: #ff3465;
		}
	}
}
.el-dialog {
	border-radius: 10px !important;
}

.login-style {
	.el-dialog__body {
		padding: 20px;
	}
	.el-input__inner {
		background-color: #f1f1f3;
		color: #aaaaaa;
		border: none !important;
	}
	.el-form-item__label {
		font-size: 15px;
		font-family: PingFang SC;
		font-weight: 500;
		color: #666666;
	}
	.dialog-button {
		height: 60px;
		background: #ff3465;
		opacity: 1;
		border-radius: 5px;
		font-size: 16px;
		font-family: PingFang SC;
		font-weight: 400;
		color: #ffffff;
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;
		user-select: none;
	}
	.dialog-text {
		margin-top: 20px;
		.dialog-text-1 {
			text-align: center;
			font-size: 14px;
			font-family: PingFang SC;
			font-weight: 500;
			line-height: 20px;
			color: #ff3465;
			user-select: none;
		}
		.dialog-text-2 {
			font-size: 14px;
			font-family: PingFang SC;
			font-weight: 500;
			line-height: 20px;
			color: #999999;
			text-align: center;
			margin-top: 10px;
			span {
				font-size: 14px;
				font-family: PingFang SC;
				font-weight: 500;
				line-height: 20px;
				color: #ff3465;
				margin: 0 5px;
				user-select: none;
				cursor: pointer;
			}
		}
	}
	.dialog-login-button {
		text-align: center;

		font-size: 14px;
		font-family: PingFang SC;
		font-weight: 500;
		line-height: 20px;
		color: #ff3465;
		cursor: pointer;
		user-select: none;
		margin-top: 40px;
	}
	.form-footer {
		display: flex;
		justify-content: flex-end;
		align-items: center;
	}
}

.tip {
	font-size: 13px;
	font-family: PingFang SC;
	font-weight: 500;
	line-height: 18px;
	color: #797a8e;
	margin-top: 8px;
}

.search-history {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	flex-wrap: wrap;
	.history-item-box {
		width: 100%;
		display: flex;
		justify-content: flex-start;
		align-items: flex-start;
		flex-wrap: wrap;
		.history-item {
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 2px 20px;
			background: #f1f1f1;
			margin-right: 10px;
			margin-bottom: 10px;
			border-radius: 5px;
			cursor: pointer;
			img {
				width: 40px;
				height: 40px;
			}
		}
	}

	.search-history-reset {
		width: 100%;
		text-align: right;
		font-size: 13px;
		font-family: PingFang SC;
		font-weight: 400;
		line-height: 18px;
		color: #ff3465;
		margin-top: 24px;
		cursor: pointer;
	}
}
.gary {
	.el-input__inner {
		color: #606266;
	}
}
.white {
	.el-input__inner {
		color: white;
	}
}
</style>
